import React from 'react';
import "./taxinvoice.scss";

const TaxInvoice = () => {
    return (
        <div className="invoice-container">
            <h1>Tax Invoice</h1>
            <header className="invoice-header">

                <div className="company-info-container">
                    <div className="comapny-info-inner">
                        <div className="company-info">
                            <h2>NearbyFreelancers</h2>
                            <p>3RD FLOOR, CABIN NO 6H, JMD Regent Arcade</p>
                            <p>M G ROAD, DLF City Phase 1, Sector-28, Gurugram</p>
                            <p>GSTIN/UIN: 06ABFDB0608R1Z5</p>
                            <p>State Name: Haryana, Code: 06</p>
                        </div>
                        <div className="buyer-info">
                            <h2>Buyer (Bill to)</h2>
                            <p><strong>M TECH DEVELOPERS PRIVATE LIMITED</strong></p>
                            <p>1, HOUSING PROJECT SITE, SANCOALE, ZUARINAGAR, South Goa, Goa, 403726</p>
                            <p> <strong className='me-4'>GSTIN/UIN </strong> : <span className='ms-4'>30AACEM5065E1ZW</span></p>
                            <p> <strong className='me-3'>State Name</strong>  : <span className='ms-4'> Goa, Code: 30</span></p>
                        </div>
                    </div>

                    <div className="infovice-information">

                        <div className="detail-box">
                            <p><strong>Invoice No.</strong><div className='detal-box-content'>BSP/24-25/TI/001</div> </p>
                        </div>
                        <div className="detail-box">
                            <p><strong>Dated:</strong> <div className='detal-box-content'>14-Sep-24</div></p>
                        </div>
                        <div className="detail-box">
                            <p><strong>Mode/Terms of Payment:</strong> <div className='detal-box-content'></div></p>
                        </div>
                        <div className="detail-box">
                            <p><strong>Reference No & Date:</strong> <div className='detal-box-content'>BSP/24-25/TI/001 dt. 14-Sep-24</div> </p>
                        </div>
                        <div className="detail-box">
                            <p><strong>Terms of Delivery:</strong></p>
                        </div>
                        <div className="detail-box">
                            <p><strong> </strong></p>
                        </div>

                    </div>

                </div>

            </header>



            <table className="invoice-table">
                <thead>
                    <tr>
                        <th>Sl No.</th>
                        <th>Description of Services</th>
                        <th>HSN/SAC</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>
                            <p>998311-DIGITAL MARKETING SERVICES-18%</p>
                            <p>Digital Brand Promotion Services</p>
                            <p>FOR THE MONTH OF SEPT. 2024</p>
                        </td>
                        <td>998311</td>
                        <td>17,797.00</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>IGST ON SALE</td>
                        <td></td>
                        <td>3,203.46</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>SHORT & EXCESS</td>
                        <td></td>
                        <td>(-0.46)</td>
                    </tr>
                    <tr >
                        <td style={{paddingTop:"40px"}}></td>
                        <td> </td>
                        <td></td>
                        <td> </td>
                    </tr>
                    <tr >
                        <td style={{paddingTop:"40px"}}></td>
                        <td> </td>
                        <td></td>
                        <td> </td>
                    </tr>
                    <tr >
                        <td style={{paddingTop:"40px"}}></td>
                        <td> </td>
                        <td></td>
                        <td> </td>
                    </tr>
                   
                    <tr className="total-row">
                        <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                        <td style={{ fontWeight: 'bold' }}>21,000.00</td>
                    </tr>
                </tbody>
            </table>

            <section className="total-amount">
                <h3>Total: ₹ 21,000.00</h3>
                <p>Amount Chargeable (in words): INR Twenty One Thousand Only</p>
            </section>

            <section className="tax-breakdown">
                <table>
                    <thead>
                        <tr>
                            <th>HSN/SAC</th>
                            <th>Taxable Value</th>
                            <th>IGST Rate</th>
                            <th>Total Tax Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>998311</td>
                            <td>17,797.00</td>
                            <td>18%</td>
                            <td>3,203.46</td>
                        </tr>
                    </tbody>
                </table>
                <p>Tax Amount (in words): INR Three Thousand Two Hundred Three and Forty Six paise Only</p>
            </section>
        </div>
    );
};

export default TaxInvoice;
