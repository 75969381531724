import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./postwork.scss"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';
import { axiosApi } from '../../../_helper/api_helper';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, Tooltip } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';



const PostWorkRecuiter = ({ getJobAll, projectId }) => {

    const navigate = useNavigate()
    const { getallRecurit, } = useSelector((state) => state.RecuiterFormReducers)

    const recuiterId = localStorage.getItem("recuiter_Id");
    useEffect(() => {
          // Or replace with your state management solution
        if (!recuiterId) {
            // Redirect to login if recruiter_Id does not exist
            navigate('/job-recuiter-login?redirectTo=/recuiter-profile/post_a_project');
        }
    }, [navigate ,recuiterId]);
    const [validationMsg, setValidationMsg] = useState('');

    const [industries, setIndustries] = useState([]);
    const [citiesGetData, setCitiesGetData] = useState([])
    const [subcategories, setSubcategories] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [payHour, setPayHour] = useState(false);
    const [fixedPrice, setFixedPrice] = useState(false);

    const [skill, setSkill] = useState('');
    const [status, setStatus] = useState('');
    const [manySkills, setManySkill] = useState([]);
    const [minBudget, setMinBudget] = useState('');
    const [maxBudget, setMaxBudget] = useState('');
    const [formvalues, setFormValues] = useState({
        Occupation_industry: "",
        project_budget_hr: "",
        project_budget: "",
        skill: "",
        project_title: "",
        deliver_period: "",
        industry: "",
        occupation_skill: "",
        city_new: '',
        project_description: '',
        max_perhour: '',
        min_perhour: '',
        max_fixedprice: '',
        min_fixedprice: '',
        number_of_hours: '',
        skills: [],
    });

    const [selectedIndustryName, setSelectedIndustryName] = useState('');
    const [selectedSkillName, setSelectedSkillName] = useState('');

    const handleChange = (e) => {
        setSkill(e.target.value);
    };
    const handlePerhours = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');


        setFormValues({ ...formvalues, [e.target.name]: numericValue })
    }
    const handleskillSoftware = (e) => {
        e.preventDefault();
        if (skill.trim()) {
            setManySkill([...manySkills, skill]);
            setSkill('');
        }
    };
    const handleSkillDelete = (index) => {
        const newSkills = manySkills.filter((_, i) => i !== index);
        setManySkill(newSkills);
    };

    const handleProjectDetailsChange = (e) => {

        setFormValues({ ...formvalues, [e.target.name]: e.target.value });
    };



    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        citiesGet();
    }, [])
    const citiesGet = async () => {
        const response = await axiosApi.get("/cities");
        setCitiesGetData(response?.data)
    }
    useEffect(() => {
        IndustriesGet();

    }, [])
    const IndustriesGet = async () => {
        const response = await axiosApi.get("/industries");
        setIndustries(response?.data)
    }
    const handleIndustryChange = (e) => {
        const selectedIndustryId = e.target.value;
        const selectedIndustry = industries.find(industry => industry.id === selectedIndustryId);

        setSelectedIndustryName(selectedIndustry ? selectedIndustry.name : '');
        setFormValues({ ...formvalues, industry: selectedIndustryId });

        if (selectedIndustryId) {
            axiosApi.get(`/industries/${selectedIndustryId}`)
                .then(response => {
                    setSubcategories(response.data.occupation_skills || []);
                })
                .catch(error => {
                    console.error("There was an error fetching the subcategories!", error);
                    setSubcategories([]);
                });
        } else {
            setSubcategories([]);
        }
    };

    const handleCategoryChange = (e) => {
        const selectedSkillName = e.target.value;
        setSelectedSkillName(selectedSkillName);
        setFormValues({ ...formvalues, occupation_skill: selectedSkillName });
    };
    useEffect(() => {
        if (projectId && industries.length > 0) {
            axiosApi.get(`/multijob/${recuiterId}/${projectId}`).then((response) => {


                const data = response.data;
                const industryId = industries.find(
                    (ind) => ind.name === data.industry
                )?.id;
                setFormValues({
                    ...data,
                    industry: industryId,

                });
                setFormValues(response?.data);
                setStatus(response?.data?.status)
                setPayHour(data.max_perhour !== null);
                setFixedPrice(data.max_fixedprice !== null);
                // setManySkill(response?.data?.skills || []);
                setManySkill(response.data?.skills?.map(skill => skill.name));
                setSelectedIndustryName(data?.Occupation_industry)

                if (industryId) {
                    // Fetch subcategories based on the industry ID
                    axiosApi.get(`/industries/${industryId}`).then((response) => {
                        const skills = response.data.occupation_skills;
                        setSubcategories(skills || []);
                        setSelectedSkillName(skills.find((d) => d.name === data?.Occupation_skills)?.name || '');

                    }).catch((error) => {
                        console.error("Error fetching subcategories:", error);
                        setSubcategories([]);
                    });
                } else {
                    setSubcategories([]);
                }





            })
        }
    }, [projectId, recuiterId, industries])



    const HandlePayHour = () => {
        setPayHour(true);
        setFixedPrice(false);
        // setCustomBudget(false);
        // setEstimateBudget(false);
    }
    const handleFixedPrice = (e) => {
        setFixedPrice(true);
        setPayHour(false);

    }


    const handleMinBudgetChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const { name, value } = e.target;
        const parsedValue = parseFloat(numericValue);

        // Update the form values state
        setFormValues(prevState => ({
            ...prevState,
            [name]: numericValue
        }));



    };

    const handleMaxBudgetChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const { name, value } = e.target;


        // Update the form values state
        setFormValues(prevState => ({
            ...prevState,
            [name]: numericValue
        }));


    };
    const validateMinBudget = (value) => {
        const parsedValue = parseFloat(value);

        // Show error only if the user finishes entering the value and it's invalid
        if (isNaN(parsedValue) || parsedValue < 100) {
            toast.error('Please fill minimum budget with a value greater than or equal to 100.');
        }
    };

    const validateMaxBudget = (value) => {
        const parsedValue = parseFloat(value);
        const minBudget = parseFloat(formvalues.min_fixedprice || 0);

        // Show error only after the user finishes typing
        if (isNaN(parsedValue) || parsedValue <= minBudget) {
            toast.error('Maximum budget should be greater than the minimum budget.');
        }
    };

    const handleSubmitEditPost = async () => {
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };

        if (!formvalues?.project_title) {
            toast.error('Project Title is required', toastOptions);
            return;
        }
        if (!formvalues.project_description) {
            toast.error('Description is required', toastOptions);
            return;
        } else if (formvalues.project_description.length < 100) {
            alert("Description must be at least 100 characters long");
            return;
        }
        if (!formvalues?.industry) {
            toast.error('Category is required', toastOptions);
            return;
        }
        if (!formvalues?.occupation_skill) {
            toast.error('Sub Category  is required', toastOptions);
            return;
        }
        if (!manySkills) {
            toast.error('Skill is required', toastOptions);
            return;
        }

        if (!payHour && !fixedPrice) {
            toast.error('Please select the option. How do you want to pay?', toastOptions);
            return;
        }
        if (payHour) {
            if (!formvalues.min_perhour || isNaN(parseFloat(formvalues.min_perhour))) {
                toast.error('Minimum per hour price is required.', toastOptions);
                return;
            }
            if (!formvalues.max_perhour || isNaN(parseFloat(formvalues.max_perhour))) {
                toast.error('Maximum per hour price is required.', toastOptions);
                return;
            }
            if (parseFloat(formvalues.min_perhour) < 100) {
                toast.error('Minimum budget per hour should be at least 100.', toastOptions);
                return;
            }
            if (parseFloat(formvalues.max_perhour) <= parseFloat(formvalues.min_perhour)) {
                toast.error('Maximum per hour price should be greater than the minimum price.', toastOptions);
                return;
            }
            if (!formvalues.number_of_hours) {
                toast.error('Please fill the number of hours.', toastOptions);
                return;
            }
        }

        if (fixedPrice) {
            if (!formvalues.min_fixedprice || isNaN(parseFloat(formvalues.min_fixedprice))) {
                toast.error('Minimum fixed price is required.', toastOptions);
                return;
            }
            if (!formvalues.max_fixedprice || isNaN(parseFloat(formvalues.max_fixedprice))) {
                toast.error('Maximum fixed price is required.', toastOptions);
                return;
            }
            if (!formvalues.min_fixedprice || formvalues.min_fixedprice < 100) {
                toast.error('Minimum fixed price should be at least 100.', toastOptions);
                return;
            }
            if (parseFloat(formvalues.max_fixedprice) <= parseFloat(formvalues.min_fixedprice)) {
                toast.error('Maximum fixed price should be greater than the minimum price.', toastOptions);
                return;
            }
        }





        try {
            // Collect form values in an object
            const payload = {
                project_title: formvalues?.project_title,
                project_description: formvalues?.project_description,
                skills: manySkills.map(skill => ({ name: skill })),
                industry: selectedIndustryName,
                occupation_skill: selectedSkillName,
                city_new: formvalues?.city_new,


            };
            if (payHour) {
                payload.min_perhour = Number(formvalues.min_perhour);
                payload.max_perhour = Number(formvalues.max_perhour);
                payload.number_of_hours = Number(formvalues.number_of_hours);

            } else if (fixedPrice) {
                payload.min_fixedprice = Number(formvalues.min_fixedprice);
                payload.max_fixedprice = Number(formvalues.max_fixedprice);
            }
            if (projectId) {
                axiosApi.put(`/multijob/${recuiterId}/${projectId}`, payload).then((response) => {
                    // alert("Hello World!");
                    navigate("/recuiter-profile/my_project");
                }).catch((error) => {
                    // Handle server-side validation errors
                    if (error.response && error.response.data && error.response.data.error) {
                        const serverErrors = error.response.data.error;
                        // Example of handling project_title error
                        if (serverErrors.project_title) {
                            toast.error(serverErrors.project_title[0], toastOptions);
                        }
                    }
                });
            } else {
                axiosApi.post(`/multijob/${recuiterId}`, payload).then((response) => {
                    // alert("Hello World!");
                    navigate("/recuiter-profile/my_project");
                }).catch((error) => {
                    // Handle server-side validation errors
                    if (error.response && error.response.data && error.response.data.error) {
                        const serverErrors = error.response.data.error;
                        // Example of handling project_title error
                        if (serverErrors.project_title) {
                            toast.error(serverErrors.project_title[0], toastOptions);
                        }
                    }
                });
            }


            // dispatch(getallRecuirit(recuiterId));
            // 
        } catch (error) {
            console.error('Error submitting form:', error);
            alert()

        }
    };




    return (
        <>

            <div className="post-project">
                <div className="post-project-inner">
                    <h5> {projectId ? "Update Project" : "Post a Project"} </h5>
                    <div className="editpost-work-title">
                        <div> <label style={{ fontWeight: "600", fontSize: "15px" }} htmlFor="">Project Title <span style={{ color: "red" }}>*</span>
                            <Tooltip placement="top" title={
                                <>
                                    <div><b>Note:</b></div>
                                    <div>Enter your job title</div>
                                    <div> e.g. XYZ Website Development,Social Media Handel</div>

                                </>
                            } arrow>
                                <InfoIcon style={{ color: "grey" }} />
                            </Tooltip>
                        </label> </div>

                        <input type="text"
                            placeholder='Enter Project Title'
                            value={formvalues?.project_title}
                            // onChange={(e) => setFormValues({ ...formvalues, project_title: e.target.value })}
                            onChange={handleProjectDetailsChange}
                            name="project_title" id="" />
                    </div>
                    <div style={{ position: 'relative' }} className="editpost-work-title">
                        <div    > <label style={{ fontWeight: "600", fontSize: "15px" }} htmlFor="">Project Description <span style={{ color: "red" }}>*</span> </label> </div>
                        <textarea type="text"

                            placeholder='Enter Description (Min 100 character)'
                            value={formvalues?.project_description}
                            onChange={handleProjectDetailsChange}
                            name="project_description"
                            rows='5'
                            disabled={status == 1}
                            id="" />
                        {status === 1 && (
                            <Tooltip title="Support is Ready to Help – Reach Out!" arrow>
                                <IconButton
                                    style={{ position: 'absolute', top: '20%', right: '10px' }}
                                >
                                    <LockIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    <div className="editpost-work-industry">
                        <div className="editpost-work-inner">
                            <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">Category <span style={{ color: "red" }}>*</span> </label> </div>
                            <select
                                onChange={handleIndustryChange}
                                //  onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })}
                                name='industry' value={formvalues?.industry} style={{ boxShadow: "none" }} class="form-select" aria-label="Default select example">
                                <option selected>Select Category</option>
                                {industries?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.id}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="editpost-work-inner">
                            <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">Sub Category <span style={{ color: "red" }}>*</span> </label> </div>
                            <select
                                onChange={handleCategoryChange}
                                name="occupation_skill"
                                value={formvalues.occupation_skill}
                                disabled={!subcategories.length && !selectedIndustry}
                                style={{ boxShadow: "none" }} class="form-select" aria-label="Default select example">
                                <option selected>Select Sub Category</option>
                                {selectedIndustry && subcategories.length === 0 && (
                                    <option value="" disabled>No records found</option>
                                )}
                                {subcategories?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.name}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                    </div>
                    <div className="skill-expertise">
                        <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">Enter the skill set
                            you want to use for this project. <span style={{ color: "red" }}>*</span> </label>
                            <Tooltip placement="top" title={
                                <>
                                    <div><b>Note:</b></div>
                                    <div>Enter your Skillset for this project</div>
                                    <div> e.g. HTML, CSS, Python,etc..</div>

                                </>
                            } arrow>
                                <InfoIcon style={{ color: "grey" }} />
                            </Tooltip>
                        </div>
                        <div className='skill-expertise-inner'>
                            <div className='skill-expertise-input'>
                                <input
                                    type="text"
                                    value={skill}
                                    fullWidth
                                    onChange={handleChange}
                                    placeholder="Enter your project skill set"
                                />
                                {/* <input onChange={handleSoftwareChange} value={formvaluesSoftware?.name} fullWidth type="text" name="name" id="" /> */}
                            </div>
                            <div className='skill-expertise-button'><button onClick={handleskillSoftware} className='btn btn-danger'>+Add Skill</button></div>
                        </div>
                        <div className='skill-expertise-data'>

                            {manySkills && manySkills.length > 0 && manySkills.map((item, index) => (
                                <>
                                    <div key={index} className='software-known-button-conatiner'>
                                        <button style={{ backgroundColor: "#F5E8DD" }} className='btn btn ms-2 software-known-button'> {item.name || item}</button>
                                        <CancelIcon
                                            className='software-konw-icon'

                                            onClick={() => handleSkillDelete(index)}
                                        />
                                    </div>

                                </>
                            ))}

                        </div>
                    </div>

                    <div className="pay-layout">
                        <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">What is your preferred payment method? <span style={{ color: "red" }}>*</span> </label> </div>
                        <div className="pay-layout-inner">
                            <div onClick={HandlePayHour}
                                className={`pay-hour ${payHour ? 'selected' : ''}`}
                            >
                                <h6>Pay by the hour</h6>

                                <p>Hire based on an hourly rate and pay for hours billed. Best for ongoing work</p>
                            </div>
                            <div onClick={handleFixedPrice}
                                className={`pay-hour ${fixedPrice ? 'selected' : ''}`}
                            >
                                <h6>Pay fixed price</h6>
                                <p>Agree on a price and release payment when  the job is done. Best for  one-off task</p>
                            </div>

                        </div>

                        <div className='toggle-budget' >
                            {payHour &&

                                <>

                                    <div className='select-main-heading'>Price Range Per Hour <Tooltip placement="top" title={
                                        <>
                                            <div><b>Note:</b></div>
                                            <div>Typically freelancer with following skillset charge the following rates</div>
                                            <div> Beginner : 100 - 500 per/hour</div>
                                            <div> Intermediate : 500+ - 1000 per/hour</div>
                                            <div> Experienced : 1000+ per/hour</div>

                                        </>
                                    } arrow>
                                        <InfoIcon style={{ color: "grey" }} />
                                    </Tooltip> ?</div>
                                    <div className='per-hour-budget'>
                                        <div className='custom-budget'>
                                            <p>Minimum Price</p>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text"><CurrencyRupeeIcon /></span>
                                                <input type="text" class="form-control"
                                                    value={formvalues?.min_perhour || ''}
                                                    aria-label="Amount (to the nearest dollar)"
                                                    placeholder='Enter minimum price'
                                                    name="min_perhour"
                                                    onChange={handleMinBudgetChange} id="minBudget"
                                                    onBlur={(e) => validateMinBudget(e.target.value)}

                                                />
                                                <span class="input-group-text">Per/Hour</span>
                                            </div>

                                        </div>
                                        <div className='custom-budget'>
                                            <p>Maximum Price</p>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text"><CurrencyRupeeIcon /></span>
                                                <input type="text"
                                                    value={formvalues?.max_perhour || ''}

                                                    name="max_perhour"
                                                    class="form-control" aria-label="Amount (to the nearest dollar)"
                                                    placeholder='Enter maximum price' onChange={handleMaxBudgetChange}
                                                    onBlur={(e) => validateMaxBudget(e.target.value)}
                                                    id="maxBudget"
                                                />
                                                <span class="input-group-text">Per/Hour</span>
                                            </div>

                                        </div>
                                        <div className='custom-budget'>
                                            <p>Number of hours required for project</p>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text"><AccessTimeFilledIcon /></span>
                                                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                                                    placeholder='Enter number of hours'
                                                    value={formvalues?.number_of_hours || ''}
                                                    name="number_of_hours"
                                                    onChange={handlePerhours}
                                                // onBlur={(e) => validateMaxBudget(e.target.value)}
                                                // id="maxBudget"
                                                />
                                                <span class="input-group-text">Per/Hour</span>
                                            </div>

                                        </div>
                                    </div>


                                    {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}
                                </>


                            }
                            {fixedPrice &&
                                <>
                                    <div className='select-main-heading'>Fixed Price Range
                                        <Tooltip placement="top" title={
                                            <>
                                                <div><b>Note:</b></div>
                                                <br />
                                                <div> Please consider the number of hours required to be invested in the project. Further, please also consider skill set requirement while determining the fixed price of the project. Typically freelancers with the following skill set charge the following rates:</div>
                                                <br />
                                                <div> Beginner : 100 - 500 per/hour</div>
                                                <div> Intermediate : 500+ - 1000 per/hour</div>
                                                <div> Experienced : 1000+ per/hour</div>
                                                <br />
                                                <div> Also please read the <Link style={{ color: "white" }} target='_blank' to={`/term-and-condition/fixed-price-escrow-instruction`}>Term and Condtion </Link>
                                                    carefully.
                                                </div>


                                            </>
                                        } arrow>
                                            <InfoIcon style={{ color: "grey" }} />
                                        </Tooltip>
                                    </div>
                                    <div className='per-hour-budget'>
                                        <div className='custom-budget'>
                                            <p>Minimum Price</p>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text"><CurrencyRupeeIcon /></span>
                                                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                                                    placeholder='Enter minimum price'
                                                    value={formvalues?.min_fixedprice || ''}
                                                    onBlur={(e) => validateMinBudget(e.target.value)}
                                                    onChange={handleMinBudgetChange} name="min_fixedprice"
                                                    id="minBudget"
                                                />
                                                {/* <span class="input-group-text">Per/Hour</span> */}
                                            </div>
                                            {/* <input type="text" placeholder='Enter minimum budget' onBlur={handleMinBudgetChange} name="min_perhour" id="minBudget" /> */}
                                        </div>
                                        <div className='custom-budget'>
                                            <p>Maximum Price</p>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text"><CurrencyRupeeIcon /></span>
                                                <input type="text"
                                                    value={formvalues?.max_fixedprice || ''}
                                                    class="form-control" aria-label="Amount (to the nearest dollar)"
                                                    placeholder='Enter maximum price' onChange={handleMaxBudgetChange}
                                                    onBlur={(e) => validateMaxBudget(e.target.value)}
                                                    name="max_fixedprice" id="maxBudget"
                                                />
                                                {/* <span class="input-group-text">Per/Hour</span> */}
                                            </div>

                                        </div>
                                    </div>
                                    {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}


                                </>

                            }



                        </div>
                        <div className="editpost-work-title">
                            <div> <label style={{ fontWeight: "600", fontSize: "15px" }} htmlFor="">Select the location you want to serve for your Project<span style={{ color: "red" }}>*</span> </label> </div>
                            <select
                                onChange={handleProjectDetailsChange}
                                //  onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })}
                                name='city_new' value={formvalues?.city_new} style={{ boxShadow: "none" }} class="form-select" aria-label="Default select example">
                                <option value={''} selected>Select Location</option>
                                {citiesGetData?.map((item) => (
                                    <>
                                        <option value={item?.city_name} style={{ textTransform: "capitalize" }}  >{item?.city_name}</option>
                                    </>
                                ))}


                            </select>
                        </div>



                    </div>



                    <div className="freelnacer-next-button">


                        <button onClick={handleSubmitEditPost} style={{ background: "black", color: "white" }} className='btn  py-2'>Save</button>
                    </div>


                </div>
            </div>
            <ToastContainer />

        </>
    )
}

export default PostWorkRecuiter