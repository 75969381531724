import axios from "axios";


const REACT_API_URL = process.env.React_APP_API_URL || "https://admin.nearbyfreelancers.com";
export const axiosApi = axios.create({
  baseURL: "https://admin.nearbyfreelancers.com",
  // baseURL:"http://127.0.0.1:8000/"  
  // baseURL:"http://3.110.181.25",
  // baseURL:REACT_API_URL

});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403) {
      console.log("Token expired, attempting to refresh...");
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");

      if (!refreshToken) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/refer-login";
        // window.location.href = "/refer-login";
        return Promise.reject(error);
      }

      try {
        const response = await axios.post("https://admin.nearbyfreelancers.com/refresh", { refresh_token: refreshToken });
        localStorage.setItem("access_token", response.data.access_token);

        // Update the original request with the new token
        error.config.headers["Authorization"] = `Bearer ${response.data.access_token}`
        originalRequest.headers["Authorization"] = `Bearer ${response.data.access_token}`;
        console.log("Token refreshed successfully, retrying the original request...");

        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Refresh token expired or failed:", refreshError);
        // localStorage.removeItem("access_token");
        // localStorage.removeItem("refresh_token");
        // window.location.href = "/refer-login";
        const response = await axios.post("https://admin.nearbyfreelancers.com/refresh", { refresh_token: refreshToken });
        localStorage.setItem("access_token", response.data.access_token);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);


export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}

// export const get = async (url, config) => {
//   try {
//     const response = await axiosApi.get(url, config);
//     return response.data;
//   } catch (error) {
//     // Handle errors
//     throw error;
//   }
// };

export async function post(url, payload, config) {
  return await axiosApi
    .post(url, payload, {
      ...config,
    })
    .then((response) => response.data);
}
export async function postReturnResponse(url, payload, config) {
  // debugger;
  return await axiosApi
    .post(url, payload, {
      ...config,
    })
    .then((response) => response);
}
export async function put(url, payload, config) {
  return await axiosApi
    .put(url, payload, {
      ...config,
    })
    .then((response) => response.data);
}
export async function remove(url, payload, config) {
  return await axiosApi
    .delete(
      url,
      { data: payload },
      {
        ...config,
      }
    )
    .then((response) => response);
}
