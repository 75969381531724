import React, { lazy, Suspense, useEffect } from 'react'
import Routers from './Route/Index';
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/index"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutRoutes from './Route/LayoutRoutes';
import SuccessPaymentNonit from './components/ReferToEarn/SuccessPaymentNonit';
// import NotFound from './components/NotFound/NotFound';
// import Loader from './Layout/Loader/Index';
// import SuucessPayment from "./components/ReferToEarn/SuucessPayment";
// import SuccessPayementJobPlain from './components/recuiterPaymentlink/SuccessPayementJobPlain';
const LoadingPannel = lazy(()=>import("./commonComponent/LoadingPannel/LoadingPannel"));
const SuccessPayementJobPlain = lazy(()=>import("./components/recuiterPaymentlink/SuccessPayementJobPlain"));
const SuucessPayment = lazy(()=>import("./components/ReferToEarn/SuucessPayment"));
const NotFound = lazy(()=>import("./components/NotFound/NotFound"));
// import LoadingPannel from './commonComponent/LoadingPannel/LoadingPannel';



const App = () => {
  // useEffect(() => {
  //   const recuiterId = localStorage.getItem("recuiter_Id");
  //   if (recuiterId) {
  //     setTimeout(() => {
  //       localStorage.removeItem("recuiter_Id");
  //     }, 24 * 60 * 60 * 1000); // 24 hours
  //   }
  // }, []);
  // useEffect(() => {
  //   const cliendId = localStorage.getItem("client_Id");
  //   if (cliendId) {
  //     setTimeout(() => {
  //       localStorage.removeItem(" ");
  //     }, 24 * 60 * 60 * 1000); // 24 hours
  //   }
  // }, []);
  return (
    <>
      {/* <Provider store={store}>
     <Routers />
     
     </Provider> */}
      <Provider store={store}>
        <BrowserRouter basename='/'>
          {/* <Suspense fallback={<div><LoadingPannel/></div>}> */}


            <Routes>
              <Route path="/*" element={<LayoutRoutes />} />
              <Route path="/payment-successfully-done" element={<SuccessPayementJobPlain />} />
              <Route path="/success-payment" element={<SuucessPayment />} />
              <Route path="/successfully-payment-done" element={<SuccessPaymentNonit />} />
              <Route path="/404" element={<NotFound />} />
            </Routes>
          {/* </Suspense> */}
        </BrowserRouter>
      </Provider>
    </>
  )
}

export default App