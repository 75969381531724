import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const SuccessModalFreelancer = ({ open, onClose ,amountdiscount}) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>🥳 Congratulations!</DialogTitle>

        <DialogContent>
            
            <p>You saved <b>{amountdiscount}%</b> on this coupon!</p>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
    </Dialog>
);

export default SuccessModalFreelancer
