import React, { useEffect, useState } from 'react'
import "./recruitergetlistdata.scss";

import { axiosApi } from '../../_helper/api_helper';

import { Link } from 'react-router-dom';

const RecruiterGetListData = () => {
    const [freelancerLeads, setFreelancerLeads] = useState([]);
    const [freelancerCount, setFreelancerCount] = useState({})
    console.log("freelancerLeads", freelancerLeads)

    useEffect(() => {
        freelancergetapi()
    }, [])
    const freelancergetapi = async () => {
        const token = localStorage.getItem("access_token");
        axiosApi.get(`/recruiter-leads`,{
            headers: {
                Authorization: `Bearer ${token}`
            } 
        }).then((response) => {
            setFreelancerLeads(response.data?.leads);
            setFreelancerCount(response.data?.counts);
        })
    }
    return (
        <>
            <div className="recuiter-lead-overview">

                <div className="recuiter-lead-overview-inner">
                    <div className="recuiter-lead-meter">
                        <div className="freelancer-overview-meter">
                            <div className='freelancer-meter-icon'>

                                <img src="/svg/nbfconeect.svg" alt="" />

                            </div>
                            <div className='freelancer-meter-content'>
                                <h2>{freelancerCount?.total_onboard}</h2>


                                <h5>Lead In Process</h5>
                            </div>

                        </div>


                    </div>
                    <div className="recuiter-lead-meter">
                        <div className="freelancer-overview-meter">
                            <div className='freelancer-meter-icon'>

                                <img src="/svg/toproject.svg" alt="" />

                            </div>
                            <div className='freelancer-meter-content'>
                                <h2>{freelancerCount?.total_in_process}</h2>


                                <h5>Lead Converted</h5>
                            </div>

                        </div>

                    </div>
                    <div className="recuiter-lead-meter">
                        <div className="freelancer-overview-meter">
                            <div className='freelancer-meter-icon'>

                                <img src="/svg/byproject.svg" alt="" />

                            </div>
                            <div className='freelancer-meter-content'>
                                <h2>{freelancerCount?.total_lead_converted}</h2>


                                <h5>Lead Rejected</h5>
                            </div>

                        </div>


                    </div>




                </div>


            </div>
            <div className="recuiter-leads-button">
                <Link to={'/refer-dashboard/add-recruiter-lead'}> <button>+ Add Project</button></Link>
            </div>
            <div className="table-container">


                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>City</th>
                        </tr>
                    </thead>
                    <tbody>
                        {freelancerLeads.length > 0 ? (
                            freelancerLeads.map((lead, index) => (
                                <tr key={index}>
                                    <td data-label="organization_name">{lead.organization_name}</td>
                                    <td data-label="contact_email">{lead.contact_email}</td>
                                    <td data-label="contact_phone">{lead.contact_phone}</td>
                                    <td data-label="city">{lead.city}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}


export default RecruiterGetListData
