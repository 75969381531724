import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, Routes, useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
import Header from '../../Layout/Header/Index';

import Footer from '../../Layout/Footer/Index';
import "./referdasboard.scss"
import FreelancerLeads from './FreelancerLeads';
import RecruiterLeads from './RecruiterLeads';
import FreelancerGetListData from './FreelancerGetListData';
import RecruiterGetListData from './RecruiterGetListData';
import CouponLeads from './CouponLeads';
import { axiosApi } from '../../_helper/api_helper';

const menuData = [
    {
        title: 'Freelancer Lead',
        path: 'freelancer-leads',

    },
    {
        title: 'Project Lead',
        path: 'recruiter-leads',

    },
    {
        title: 'Coupon',
        path: 'coupon-leads',

    },

];
const RecursiveMenu = ({ items }) => {
    const navigate = useNavigate();
    const { subPath, subItem } = useParams();
    const [openPaths, setOpenPaths] = useState([]);
    const location = useLocation();
    const handleMenuClick = (path) => {
        if (openPaths.includes(path)) {
            setOpenPaths(openPaths.filter((p) => p !== path));
        } else {
            setOpenPaths([...openPaths, path]);
        }
        navigate(`/refer-dashboard/${path}`);
    };
    const isActive = (path) => {
        return subPath === path || subItem === path || location.pathname.includes(path);
    };
    return (
        <ul>
            {items.map((item) => (
                <li key={item.path}>
                    <button
                        className={isActive(item.path) ? 'active' : ''}
                        onClick={() => handleMenuClick(item.path)}
                    >
                        {item.title}

                    </button>
                    <div className='inner-subdropdowns'>
                        {item.subItems && openPaths.includes(item.path) && (
                            <RecursiveMenu items={item.subItems} />
                        )}
                    </div>

                </li>
            ))}
        </ul>
    )
}
const ReferDashboard = () => {
    const history = useNavigate();
    const [couponsData, setCouponsdata] = useState();
    console.log("couponsData", couponsData)
    useEffect(() => {
        getCouponsDataApi()
    }, []);

    const getCouponsDataApi = async () => {
        try {
            const result = await axiosApi.get('/customerdatas');
            setCouponsdata(result?.data)
        } catch (error) {
            console.error("Error fetching client data:", error);

        }

    }

    const handleClick = () => {
        localStorage.clear();

        history("/")
        window.location.reload()
    }
    const totalEarning = couponsData?.reduce((sum, item) => sum + (item?.total_earning || 0), 0) || 0;

    return (
        <>
            <div className="influencer-dashboad-main-header">
                {/* <Header /> */}
                <nav style={{ border: "1px solid lightgrey" }} class="navbar navbar-light bg-light">
                    <div class="container">
                        <Link class="navbar-brand" to="/refer-dashboard/freelancer-leads">
                            <img src="/image/nbflogo.png" alt="" width="60" height="40" />
                        </Link>
                        <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                            <div style={{border: "1px solid grey", padding: "7px 20px 7px 20px", borderRadius:"18px"}}><img src="/svg/wallet.svg" alt="" /> {parseInt(totalEarning)}</div>
                            <button className='btn btn-primary' onClick={handleClick}>Logout</button>
                        </div>

                    </div>
                </nav>
            </div>
            <div class="navbar pe-3 ps-3 influencer-dashboard-mobile">
                <Link to="/"><img src="/image/nbflogo.png" alt="" class="navbar-brand" /></Link>
                <div class="navbar-option d-flex justify-content-between align-items-center">
                    {/* <img src="images/bell-notification.svg" class="pe-1" alt="" /> */}


                    {/* <button style={{ width: "40px", height: "40px", border: "1px solid green", borderRadius: "50%", objectFit: "contain" }} class=" me-2">
                        <img style={{ width: "40px", height: "40px", border: "1px solid lightGrey", borderRadius: "50%", objectFit: "contain" }} src={ProfilePicURL} alt="" />

                    </button> */}
                    <button style={{ boxShadow: "none" }} class="btn menu-btns" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                        aria-controls="offcanvasExample"><img tyle={{ width: "80px", height: "40px", objectFit: "contain" }} src="/svg/dashboardmenu.svg" alt="menu-icon" /></button>
                </div>
            </div>

            <div class="offcanvas offcanvas-start mobile-canvas-container" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header p-0">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Influencer Dashboard</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body mobile-canvas p-0 pt-3">
                    <div class="menu-items">
                        <Link to={'freelancer-leads'} class="menu-link active">Freelancer Lead</Link>
                        <Link to={'recruiter-leads'} class="menu-link active">Project Lead</Link>

                        <Link to={'log_out'} class="menu-link">Logout</Link>
                    </div>
                </div>

            </div>
            {/* <DashboardHeader mobile={'788876777'}/> */}
            <div className="influencer-profile-container">
                <div className="influencer-profile-inner">
                    <div className="sidebar">
                        <ul>
                            <h6>Influencer Dashboard</h6>
                            <RecursiveMenu items={menuData} />
                        </ul>
                    </div>
                    <div className="content">
                        <Routes>
                            <Route path="/" element={<Navigate to="freelancer-leads" replace />} />
                            <Route path=":subPath/:subItem" element={<ContentDisplay />} />
                            <Route path=":subPath" element={<ContentDisplay />} />
                            {/* <Route path=":/edit_project/:projectId" element={<ContentDisplay />} /> */}

                        </Routes>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}
const ContentDisplay = () => {
    const clientId = localStorage.getItem('client_Id');

    const navigate = useNavigate();

    const { subPath, subItem } = useParams();
    const projectId = subItem





    return <div className='terms-content'>{getContent(subPath, subItem, navigate, projectId, clientId)}</div>;
};
const getContent = (subPath, subItem, projectId) => {

    switch (subPath) {
        case 'freelancer-leads':
            return (<>

                <FreelancerGetListData />
                {/* <FreelancerLeads projectId={subPath} /> */}



            </>)
        case 'add-freelancer-lead':
            return (<>

                {/* <ProjectInterest /> */}
                <FreelancerLeads projectId={subPath} />



            </>)
        case 'recruiter-leads':
            return (<>

                <RecruiterGetListData projectId={subPath} />



            </>)

        case 'add-recruiter-lead':
            return (<>

                {/* <ProjectInterest /> */}
                <RecruiterLeads projectId={subPath} />



            </>)
        case 'coupon-leads':
            return (<>

                {/* <ProjectInterest /> */}
                <CouponLeads projectId={subPath} />



            </>)







    }
}

export default ReferDashboard
