import React, { useState } from 'react';
import "./freelancerleads.scss"
import { axiosApi } from '../../_helper/api_helper';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FreelancerLeads = () => {
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone: '',
        city:''

    });
    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
          };
          if (!formValues?.name) {
            toast.error('Name is required', toastOptions);
            return;
          }
          if (!formValues?.email) {
            toast.error('Email is required', toastOptions);
            return;
          }
          if (!formValues?.phone) {
            toast.error('Phone is required', toastOptions);
            return;
          }
          if (!formValues?.city) {
            toast.error('City is required', toastOptions);
            return;
          }
        const payload = {
            name: formValues?.name,
            email: formValues?.email,
            phone: formValues?.phone,
            city:formValues?.city,
        };

        const token = localStorage.getItem("access_token");  // Replace this with the actual token

        axiosApi.post(`/freelancer-leads`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            } 
        })
        .then(response => {
            navigate("/refer-dashboard/freelancer-leads");
            
                toast.success('Successfully posted', toastOptions);
                return;
            
            console.log("Success:", response.data);
        })
        .catch(error => {
            console.error("There was an error:", error);
        });
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Name <span style={{color:"red"}}>*</span> </label>
                    <div class="col-sm-9">
                        <input type="text" name='name' placeholder='Enter the name' value={formValues?.name} class="form-control" onChange={handleChange} id="inputEmail3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label">Email <span style={{color:"red"}}>*</span> </label>
                    <div class="col-sm-9">
                        <input type="email" name='email' placeholder='Enter the email' value={formValues?.email} class="form-control" onChange={handleChange} id="inputPassword3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label">Phone <span style={{color:"red"}}>*</span></label>
                    <div class="col-sm-9">
                        <input type="number" name='phone' placeholder='Enter the phone' value={formValues?.phone} class="form-control" onChange={handleChange} id="inputPassword3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label">City <span style={{color:"red"}}>*</span></label>
                    <div class="col-sm-9">
                        <input type="text" name='city' placeholder='Enter the city' value={formValues?.city} class="form-control" onChange={handleChange} id="inputPassword3" />
                    </div>
                </div>


                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
      <ToastContainer />

        </>
    )
}

export default FreelancerLeads
