import React, { useEffect, useState } from 'react'
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faPhoneVolume, faBuilding, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from 'react-router-dom';
import "./footer.scss"
import { axiosApi } from '../../_helper/api_helper';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BrochureModel from './BrochureModel';

const Footer = () => {
  const recuiterId = localStorage.getItem("recuiter_Id");
  const client_Id = localStorage.getItem("client_Id");
  const PDF_FILE = "Freelancing PPT.pdf"
  const [subscribeValues, setSubscribeValues] = useState({
    email: ""
  })
  const [categoriesData, setCategories] = useState([]);
  const [cities, setCities] = useState([])
  
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    categoriesGet();
    citiesGet();
  }, [])

  const categoriesGet = async () => {
    const response = await axiosApi.get("/industries");
    setCategories(response?.data)
  }
  const citiesGet = async () => {
    const response = await axiosApi.get("/cities");
    setCities(response?.data)
  }

  const submitSubscribe = (e) => {
    e.preventDefault();
    const payload = {
      email: subscribeValues?.email
    }
    axiosApi.post("/newsletter", payload).then((response) => {
      
      setSubscribeValues({
        email: ""
      })
      toast.success('Thank you for subscribing our letter', {
        position: 'top-left',
      });

    })
  }
  const handlePDF = (url) => {
    if (url) {
      

      const fileName = url.split("/").pop();
       

      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    } else {
      console.error("PDF file URL is undefined");
    }
  };
  const sanitizeSkillName = (name) => {
    // Replace special characters with spaces
    const sanitizedName = name.replace(/[^\w\s]/gi, ' ');

    // Trim extra spaces from the beginning and end of the string
    return sanitizedName.trim();
  }

  return (
    <>

      <div className="footer">
        <div className="footer-inner-container">


          <div className="footer-container">
            <div className="footer-logo">
              {/* <img src="/image/footerLogo.png" alt="" /> */}
              <h5>Join Our Newsletter</h5>
              <p>Be the first to know about our latest updates, exclusive offers, and more.</p>

            </div>
            <div className="footer-subscribe">
               
                <form onSubmit={submitSubscribe} action="">
                  <div className='subscribe-input'><input required type="email" placeholder='Enter your email address' value={subscribeValues?.email} name="email" onChange={(e) => setSubscribeValues({ ...subscribeValues, [e.target.name]: e.target.value })} id="" /></div>
                  <div className='subscribe-button'><button type='submit' className='btn'>Subscribe</button></div>
                </form>

 
             
            </div>
          </div>

          {/* <div className="footer-category">
            <div className='category-heading'><h5><ContentCopyIcon /> Categories</h5></div>
            <div className='categories-data'>

              {categoriesData?.map((item) => (
                <>
                  <div className='categories-skill'>
                    {recuiterId ? <Link to={`/Search-recuiter/skill/${item?.skill_name}`}>{sanitizeSkillName(item?.skill_name)}</Link> :
                      <Link to={`/Serach-Freelancer/industry/${item?.skill_name}`}>{sanitizeSkillName(item?.skill_name)}</Link>
                    }

                  </div>
                </>
              ))}
            </div>
          </div> */}
          <div className="footer-category">
            <div className='category-heading'><h5><ContentCopyIcon /> Categories</h5></div>
            <div className='categories-data'>
              {categoriesData?.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div className='categories-skill'>
                    {recuiterId ? (
                      <Link to={`/Search-recuiter/skill/${item?.name}`}>
                        {sanitizeSkillName(item?.name)}
                      </Link>
                    ) : (
                      <Link to={`/Serach-Freelancer/industry/${item?.name}`}>
                        {sanitizeSkillName(item?.name)}
                      </Link>
                    )}
                  </div>
                  {index < categoriesData.length - 1 && <span> | </span>}
                </React.Fragment>
              ))}
            </div>
          </div>
          {/* <div className="footer-location">
            <div className='location-heading'><h5><LocationOnIcon /> Locations</h5></div>
            <div className='location-data'>
              {cities?.map((item, index) => (
                <React.Fragment key={item.id}>
                  {client_Id ? (
                    <Link className='location-city' to={`/Search-recuiter/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  ) : (
                    <Link className='location-city' to={`/Serach-Freelancer/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  )}
                  {index < cities.length - 1 && <span>|</span>}
                </React.Fragment>
              ))}
            </div>
          </div> */}
          <div className="footer-location">
            <div className='location-heading'><h5><LocationOnIcon /> Locations</h5></div>
            <div className='location-data'>
              {cities.map((item, index, array) => (
                <React.Fragment key={item.id}>
                  {client_Id ? (
                    <Link className='location-city' to={`/Search-recuiter/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  ) : (
                    <Link className='location-city' to={`/Serach-Freelancer/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  )}
                  {index < array.length - 1 && <span>|</span>}
                </React.Fragment>
              ))}
              {/* {[...cities].sort((a, b) => {
                if (a.city_name === "Delhi-NCR") return -1;
                if (b.city_name === "Delhi-NCR") return 1;
                return 0;
              }).map((item, index, array) => (
                <React.Fragment key={item.id}>
                  {client_Id ? (
                    <Link className='location-city' to={`/Search-recuiter/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  ) : (
                    <Link className='location-city' to={`/Serach-Freelancer/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  )}
                  {index < array.length - 1 && <span>|</span>}
                </React.Fragment>
              ))} */}
            </div>
          </div>
          <div className="footer-about-section">
            <div className='footer-about-links1'>
              <h5>Useful Links</h5>
              <p><Link to='/about-us'>About us</Link></p>
              {/* <p><Link to='/'>How it works</Link></p> */}
              {/* <p><Link to='/blog'>Blog</Link></p> */}
              <p><Link to='/refer-login'>Refer / Influencer Club</Link></p>
              <p><Link to='/contact-us'>Contact Us</Link></p>
              <p className='footer-email'>support@nearbyfreelancers.com</p>
              <h5>Details</h5>
              <p style={{ cursor: "pointer" }} onClick={handleOpenModal} className='footer-email'>Download Brochure</p>
              <p>
                {client_Id ? (<Link to='/credits'>Price Plan</Link>) :recuiterId ? (<Link to='/credits-plan'>Price Plan</Link>) :null }
                

              </p>

            </div>
            <div className='footer-about-links2'>
              <h5>Terms</h5>
              <p><Link to='/privacy-and-policy'>Privacy Policy</Link></p>
              <p><Link to='/term-and-condition'>Terms of Service</Link></p>
              {/* <p><Link to='/ '>Fees and Charges</Link></p> */}
              {/* <p><Link to='/ '>User Agreement</Link></p> */}
            </div>
            <div className='footer-about-links3'>
              <h5>For Freelancer</h5>
              <p>
                {!client_Id ?
                  <Link to='/register-as-freelancer'>Find Project</Link> :
                  <Link to='/all-projects'>Find Project</Link>
                }

              </p>
              <p>
                <Link to='/register-as-freelancer'>Freelancer Login</Link>
              </p>

            </div>
            <div className='footer-about-links4'>
              <h5>For Recuiter</h5>
              <p>
                {recuiterId ?
                  <Link to='/ '>Hire Freelancer</Link> :
                  <Link to='/job-recuiter-login'>Hire Freelancer</Link>
                }

              </p>
              <p><Link to='/job-recuiter-login'>Client Login</Link></p>

            </div>
            {/* <div className='footer-about-links5'>
              <h5>Contact Us</h5>
              <p className='footer-email'>
                Cabin No.6G, 4th Floor, JMD Regent Arcade Mall, A-Block, DLF Phase-1, Sector-28, MG Road, Gurugram, Haryana, 122002
                Gurugram
              </p>

            


              <p><a href='tel:9625318281'>9625318281</a></p>
              <h3>Headquarter</h3>
              <p> Gurugram</p>


            </div> */}
          </div>
          <div className="footer-social-links">
            <div className="social-links-flex1"><img src="/svg/nbffooter.svg" alt="" /></div>
            <div className="social-links-flex2"><h6>© 2024 Nearby Freelancers. All Rights Reserved.</h6></div>
            <div className="social-links-flex3">
              <Link target='_blank' to="https://www.facebook.com/nearbyfreelancer">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faFacebookF} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px' }} />
              </Link>

              <Link target='_blank' to="https://www.linkedin.com/company/nearbyfreelancers/">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faLinkedinIn} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px' }} />

              </Link>
              <Link target='_blank' to="https://www.instagram.com/nearbyfreelancers?igsh=MWg0cGhkZmliYWY4eQ==">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faInstagram} style={{ borderRadius: '50%', background: 'white', color: "black",width: '25px', height: '25px', fontSize: '25px' }} />

              </Link>
              <Link target='_blank' to="https://www.youtube.com/@nearbyfreelancers">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faYoutube} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px'  }} />

              </Link>

              {/* <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faTwitter} style={{ borderRadius: '50%', background: 'white', color: "#3C486B", width: '30px', height: '30px', fontSize: '30px' }} /> */}
            </div>
          </div>
        </div>
        <BrochureModel
          pdf={() => handlePDF(PDF_FILE)}
          show={showModal}
          handleClose={handleCloseModal}
        />
        <ToastContainer />

      </div>
    </>
  )
}

export default Footer

{/* <div style={{ background: 'black', color: 'white', padding: '3rem' }} className="continer-fluid mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 text-center text-md-start ">
              <h4 className='text-center text-md-start'>Categories </h4>
              <h5 className='mt-4' style={{ fontWeight: '200' }}>Web Designing</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Web Development</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Wordpress Development</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>App Development</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Graphic Development</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Search Engine Optimization</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Socail Media Marketing</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Content Writer</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Google Adward</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Media Ads</h5 >
            </div>
            <div className="col-12 col-md-3 text-center text-md-start mt-4 mt-md-0">
              <h4 className='text-center text-md-start'> Terms </h4>
              <h5 className='mt-3' style={{ fontWeight: '200' }}><Link style={{ color: "white" }} to='/privacy-and-policy'>Privacy Policy</Link> </h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}><Link style={{ color: "white" }} to='/term-and-condition'>Term & Condition</Link> </h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>Fees and Charges</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}>User Agreement</h5 >

            </div>
            <div className="col-12 col-md-3 text-center text-md-start mt-4 mt-md-0">
              <h4 className='text-center text-md-start'> About </h4>
              <h5 className='mt-3' style={{ fontWeight: '200', color: "white" }} ><Link style={{ color: "white" }} to='/about'>About Us</Link> </h5 >

              <h5 className='mt-3' style={{ fontWeight: '200' }}>How it works</h5 >
              <h5 className='mt-3' style={{ fontWeight: '200' }}><Link style={{ color: "white" }} to='/blog'>Blog</Link> </h5 >

              <h5 className='mt-3' style={{ fontWeight: '200' }}><Link style={{ color: "white" }} to='/contact-us'>Contact Us</Link></h5 >

            </div>
            <div className="col-12 col-md-3 text-center text-md-start mt-4 mt-md-0">
              <h4 className='text-center text-md-start'>Contact </h4>
              <h5 className='mt-3 mt-md-4'><WifiCalling3OutlinedIcon style={{ fontWeight: '200' }} />  <span style={{ fontWeight: '200' }} className='ms-2'>9625318281</span>  </h5>
              <h5 style={{ fontWeight: '200', display: "flex", alignItems: "center" }}><MarkAsUnreadOutlinedIcon style={{ fontWeight: '200' }} /> <span style={{ fontWeight: '200', fontSize: '16px' }} className='ms-1'>nearbyfreelancers@gmail.com</span>  </h5>
              <h5 style={{ fontWeight: '200' }}><FontAwesomeIcon icon={faBuilding} /> <span className='ms-2'>Gurugram</span>  </h5>
              <div className=' d-flex mt-5 text-center text-md-start'>
                <div className="d-none d-md-block"><LocationOnIcon /> </div>
                <div className="ms-1">
                  <h3 style={{ fontWeight: '500' }}>Location</h3>
                  <h5 style={{ fontWeight: '200' }}>Gurugram , Delhi,Noida,</h5>
                  <h5 style={{ fontWeight: '200' }}>Faridabad , Ghaziabad</h5>
                </div>
              </div>
              <h5>  </h5>
            </div>
          </div>
        </div>
        <div style={{ borderTop: "1px solid white" }} className="container mt-3">
          <div className="row mt-4">
            <div className="col-12 col-md-3 text-center text-md-start">
              <img src="/image/footerLogo.png" alt="" />
            </div>
            <div className="col-12 col-md-6 text-center mt-4 mt-md-0">
              <h5 style={{ fontWeight: '200' }}>© Near By Freelancers International Ltd. 2023</h5>
            </div>
            <div className="col-12 col-md-3 text-center text-md-start mt-3 mt-md-0">
              <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faFacebookF} style={{ borderRadius: '50%', background: 'white', color: "#3C486B", width: '30px', height: '30px', fontSize: '30px' }} />
              <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faTwitter} style={{ borderRadius: '50%', background: 'white', color: "#3C486B", width: '30px', height: '30px', fontSize: '30px' }} />
              <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faLinkedinIn} style={{ borderRadius: '50%', background: 'white', color: "#3C486B", width: '30px', height: '30px', fontSize: '30px' }} />
              <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faInstagram} style={{ borderRadius: '50%', background: 'white', color: "#3C486B", width: '30px', height: '30px', fontSize: '30px' }} />

            </div>
          </div>
        </div>
      </div> */}