import React, { useState } from 'react';
import "./forgotpassword.scss"


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { axiosApi } from '../../_helper/api_helper';

const ForgotPassword = () => {
    const navigate = useNavigate()
    const { token } = useParams();
    const [formValues, setFormValues] = useState({
        new_password: "",
        password_confirmation: "",
    })
    const handleResetChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            new_password: formValues?.new_password,
            password_confirmation: formValues?.password_confirmation,
            token: token,
        }
        try {
            const response = await axiosApi.post(`/reset-password/${token}`, payload);
            toast.success(response?.data?.message, {
                position: 'top-center',
            });
            navigate("/refer-login")
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error("Your email is already exits.", {
                    position: 'top-center',
                });
            } else if (error.response) {
                // Handle other server-side errors
                alert(`An error occurred: ${error.response.statusText || "Unknown error"}`);
            } else {
                // Handle network or client-side errors
                alert("A network error occurred. Please try again later.");
            }
        }
    }
    return (
        <>
            <div className="forgot-email">
                <img src="/ " alt="" />
                <div className="forgot-email-container">
                    <form onSubmit={handleSubmit} className="row g-3">


                        <div className="col-12">
                            <label for="inputAddress" className="form-label">New Password</label>
                            <input type="text" className="form-control" id="inputAddress" value={formValues?.new_password} name='new_password' onChange={handleResetChange} placeholder="New Password" />
                        </div>
                        <div className="col-12">
                            <label for="inputAddress2" className="form-label">Confirm Password</label>
                            <input type="text" className="form-control" id="inputAddress2" value={formValues?.password_confirmation} name='password_confirmation' onChange={handleResetChange} placeholder="Confirm Password" />
                        </div>



                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Sign in</button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />

        </>
    )
}

export default ForgotPassword
