import React, { useEffect, useState } from 'react';
import './couponleads.scss';
import { axiosApi } from '../../_helper/api_helper';

const CouponLeads = () => {
    const[couponsLeads,setCouponLeads]=useState();
    console.log("couponsLeads",couponsLeads)

    useEffect(()=>{
        couponCodeApi();
    },[])

    const couponCodeApi=async()=>{
        try {
            const response=await axiosApi.get('/customerdatas')
        setCouponLeads(response.data)
        console.log(response.data)
        } catch (error) {
            console.error("Error fetching coupon data:", error);
        }


       
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };
    return (
        // <div className="coupon-conainer">  

        // {couponsLeads?.map((coupon) => (
        //         <div className="coupon" key={coupon.id}>
        //             <div className="coupon-left">
        //                 <span className="discount-text">  {parseInt(coupon.percentage)}% OFF</span>
        //             </div>
        //             <div className="coupon-right">
        //                 <div className="coupon-header">
        //                     <h3 className="coupon-code">{coupon.coupon}</h3>
        //                 </div>
        //                 <p className="coupon-subtext">Total Earning: ₹{parseInt(coupon.total_earning)}</p>
        //                 <p className="coupon-details">
        //                     {coupon.coupon_code_discription}
        //                 </p>
        //                 <p className="coupon-date"> Created At: {formatDate(coupon.created_at)}</p>
        //             </div>
        //         </div>
        //     ))}     
           
        // </div>
         <div className="coupon-conainer">
         {couponsLeads && couponsLeads.length > 0 ? (
             couponsLeads.map((coupon) => (
                 <div className="coupon" key={coupon.id}>
                     <div className="coupon-left">
                         <span className="discount-text">{parseInt(coupon.percentage)}% OFF</span>
                     </div>
                     <div className="coupon-right">
                         <div className="coupon-header">
                             <h3 className="coupon-code">{coupon.coupon}</h3>
                         </div>
                         <p className="coupon-subtext">Total Earning: ₹{parseInt(coupon.total_earning)}</p>
                         <p className="coupon-details">{coupon.coupon_code_discription}</p>
                         <p className="coupon-date">Created At: {formatDate(coupon.created_at)}</p>
                     </div>
                 </div>
             ))
         ) : (
             <div className="no-data-message">There is no coupon at that time .</div>
         )}
     </div>

    );
}

export default CouponLeads;
