import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PublicRoute, RoleBasedRoute } from './RoleBasedRoutes';
import ApplicantForJob from '../components/FindRecuriter/commoncomponent/ApplicantForJob';
import ReferEarn from '../components/refertoearnnew/ReferEarn';
import SignUpReferalNew from '../components/refertoearnnew/SignUpReferalNew';
import PostWorkRecuiter from '../components/FindRecuriter/component/PostWorkRecuiter';
import VerifyEmail from '../components/refertoearnnew/VerifyEmail';
import ForgotPassword from '../components/refertoearnnew/ForgotPassword';
import ReferDashboard from '../components/ReferClub/ReferDashboard';
import ProjectPost from '../components/ProjectPostwihtoutLogin/ProjectPost';
import TaxInvoice from '../components/Taxinvoice/TaxInvoice';
import TesterPaymentNonit from '../components/ReferToEarn/TesterPaymentNonit';
import TesterPayment from '../components/ReferToEarn/TesterPayment';

const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const Home = lazy(() => import('../components/Home/Index'));
const Registration = lazy(() => import('../authentication/registration/Registration'));
const Login = lazy(() => import('../authentication/login/Login'));
const Blog = lazy(() => import('../components/Blog/Blog'));
const BlogsById = lazy(() => import('../components/Home/compoenet/ourBlog/BlogsById'));
const About = lazy(() => import('../components/About/About'));
const Contact = lazy(() => import('../components/Contact/Index'));
const PrivacyandPolicy = lazy(() => import('../components/privacyandpolicy/PrivacyandPolicy'));
const TermsAndAcondtion = lazy(() => import("../components/termandcondition/TermsAndAcondtion"));
const ReferToEarn = lazy(() => import('../components/ReferToEarn/ReferToEarn'));
const BuyPoints = lazy(() => import('../components/ReferToEarn/BuyPoints'));
const SingleProfile = lazy(() => import('../components/FindFreeLancers/componenets/SingleProfile'));
const FindFreeLancer = lazy(() => import('../components/FindFreeLancers/Index'));
const FreelancerDashboardIndex = lazy(() => import('../components/freelancerDashboardProfile/FreelancerDashboardIndex'));
const NewLoginForm = lazy(() => import('../components/LoginProfile/NewLoginForm'));
const ProfileCompleted = lazy(() => import('../components/LoginProfile/compoenets/ProfileCompleted'));
const FindRecuiter = lazy(() => import('../components/FindRecuriter/Index'));
const AllRecuiter = lazy(() => import('../components/FindRecuriter/AllRecuiter'));
const Interested = lazy(() => import('../components/FindRecuriter/Interested'));
const HireTalentJob = lazy(() => import('../components/hiretalentjob/HireTalentJob'));
const NewRecuriterForm = lazy(() => import('../components/JobRecuriter/NewRecuriterForm'));
const RecuiterProfile = lazy(() => import('../components/FindRecuriter/RecuiterProfile'));
const SuccessFreelancer = lazy(() => import('../components/FindFreeLancers/componenets/SuccessFreelancer'));
const ViewAllFreelancer = lazy(() => import('../components/FindFreeLancers/componenets/ViewAllFreelancer'));
const RecuiterProfileCompleted = lazy(() => import('../components/JobRecuriter/components/RecuiterProfileCompleted'));
const SuccessJob = lazy(() => import('../components/hiretalentjob/SuccessJob'));
const Resume = lazy(() => import('../components/FindFreeLancers/componenets/Resume'));
const Texting = lazy(() => import('../Layout/Header/Texting'));
const ApplicantForProject = lazy(() => import('../components/FindRecuriter/commoncomponent/ApplicantForProject'));
const RecuiterCredits = lazy(() => import('../components/recuiterPaymentlink/RecuiterCredits'));
const LoadingPannel = lazy(() => import('../commonComponent/LoadingPannel/LoadingPannel'));




const LayoutRoutes = () => (


  <Suspense fallback={<div><LoadingPannel /></div>}>

    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Home />} />
      <Route path="/Freelancer/:id" element={<SingleProfile />} />
      <Route path="/resume/:id" element={<Resume />} />
      <Route path="/Serach-Freelancer/location/:city" element={<FindFreeLancer />} />
      <Route path="/Serach-Freelancer/industry/:skill" element={<FindFreeLancer />} />
      <Route path="/register-as-freelancer" element={<Registration />} />
      <Route path="/job-recuiter-login" element={<Login />} />
      <Route path="/credits" element={<BuyPoints />} />
      <Route path="/recuiter-profile-completed" element={<RecuiterProfileCompleted />} />
      <Route path="/refer-login" element={<ReferEarn />} />
      <Route path="/refer-to-earn-signup" element={<SignUpReferalNew />} />
      {/* <Route path="/success-payment" element={<NotFound />} /> */}
      <Route path="/all-projects" element={<AllRecuiter />} />
      <Route path="/hire-talent" element={<HireTalentJob />} />
      <Route path="/view-all-freelancer" element={<ViewAllFreelancer />} />
      <Route path="/view-all-freelancer/:category/:subcategory" element={<ViewAllFreelancer />} />
      <Route path="/hire-talent/:category/:subcategory" element={<HireTalentJob />} />
      <Route path="/all-projects/:category/:subcategory" element={<AllRecuiter />} />
      <Route path="/credits-plan" element={<RecuiterCredits />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogsById />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/term-and-condition/*" element={<TermsAndAcondtion />} />
      <Route path="/privacy-and-policy" element={<PrivacyandPolicy />} />
      {/* <Route path="/refer-to-earn" element={<ReferToEarn />} /> */}
      <Route path="/verify-email/:token" element={<VerifyEmail />} />
      <Route path="/forgot-password/:token" element={<ForgotPassword />} />
      <Route path="/refer-dashboard/*" element={<ReferDashboard />} />
      <Route path="/project-post" element={<ProjectPost />} />
      <Route path="/tax-invoice" element={<TaxInvoice />} />
      <Route path="/credits-non-it-tester" element={<TesterPaymentNonit />} />
      <Route path="/credits-it-tester" element={<TesterPayment />} />

      <Route path="/recuiter-profile/*" element={<RecuiterProfile />} >
        <Route path="post_a_project" element={<PostWorkRecuiter />} />

      </Route>
      {/* Client routes */}
      <Route element={<RoleBasedRoute allowedRoles={['client']} />}>
        <Route path="/job-recuiter-login" element={<Login />} />
        <Route path="/Profile" element={<NewLoginForm />} />
        <Route path="/Profile/professional-detail" element={<NewLoginForm />} />
        <Route path="/Profile/portfolio" element={<NewLoginForm />} />
        <Route path="/Profile/payment-details-security" element={<NewLoginForm />} />
        <Route path="/profile-completed" element={<ProfileCompleted />} />
        <Route path="/Search-recuiter/skill/:skill" element={<FindRecuiter />} />
        <Route path="/Search-recuiter/location/:city" element={<FindRecuiter />} />
        <Route path="/all-projects" element={<AllRecuiter />} />
        <Route path="/all-projects/:name" element={<AllRecuiter />} />
        <Route path="/Success/:id" element={<Interested />} />
        <Route path="/Success-job/:id" element={<SuccessJob />} />
        <Route path="/hire-talent" element={<HireTalentJob />} />
        <Route path="/hire-talent/:name" element={<HireTalentJob />} />
        {/* <Route path="/success-payment" element={<SuucessPayment />} /> */}
        {/* <Route path="/recuiter-profile-completed" element={<RecuiterProfileCompleted />} /> */}
        <Route path="/freelancer-dashboard/*" element={<FreelancerDashboardIndex />} />
        <Route path="/refer-to-earn" element={<ReferToEarn />} />
        <Route path="/credits" element={<BuyPoints />} />
        <Route path="/texting" element={<Texting />} />

      </Route>





      {/* Recruiter routes */}

      <Route element={<RoleBasedRoute allowedRoles={['recruiter']} />}>
        <Route path="/register-as-freelancer" element={<Registration />} />
        <Route path="/Freelancer/:id" element={<SingleProfile />} />
        <Route path="/Serach-Freelancer/location/:city" element={<FindFreeLancer />} />
        <Route path="/Serach-Freelancer/industry/:skill" element={<FindFreeLancer />} />
        <Route path="/job-recuiter-login" element={<Login />} />
        <Route path="/HireFreeLancer/personal-details" element={<NewRecuriterForm />} />
        <Route path="/HireFreeLancer/picks" element={<NewRecuriterForm />} />
        <Route path="/HireFreeLancer/job-post" element={<NewRecuriterForm />} />
        <Route path="/HireFreeLancer/project-post" element={<NewRecuriterForm />} />
        <Route path="/recuiter-profile/*" element={<RecuiterProfile />} />
        <Route path="/applicants-regaring-project/:id" element={<ApplicantForProject />} />
        <Route path="/applicants-regaring-by-project/:name" element={<ApplicantForProject />} />
        <Route path="/applicants-regarding-job/:id" element={<ApplicantForJob />} />
        <Route path="/SuccessFreelancer/:name" element={<SuccessFreelancer />} />
        <Route path="/recuiter-profile-completed" element={<RecuiterProfileCompleted />} />
        <Route path="/view-all-freelancer" element={<ViewAllFreelancer />} />
        <Route path="/credits-plan" element={<RecuiterCredits />} />
      </Route>


      {/* Catch-all */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>

)

export default LayoutRoutes