import React, { useState } from 'react';
import "./refertoearn.scss";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { axiosApi } from '../../_helper/api_helper';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';
const ReferEarn = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState(null);
    const [formValues, setFormValues] = useState({
        email: "",
        password: "",
    });
    const [errorLogin, setErrorLogin] = useState(null);
    const [showForgotEmail, setShowForgotEmail] = useState(false);  // State for forgot password email input
    const [forgotFormvalues, setForgotFormvalues] = useState({
        forgotEmail: '',
    });
    const [loading, setLoading] = useState(false)

    const handleGoogleLoginSuccess = (response) => {
        console.log("checkResponse", response);
        // You can now store the user's info in your state or context
    };
    const login = useGoogleLogin({
        onSuccess: codeResponse => console.log(codeResponse),
        flow: 'auth-code',
    });
    const handleGoogleLoginFailure = (error) => {
        console.log("Google Login Failed", error);
    };

    const handleLogout = () => {
        googleLogout(); // Log the user out
        setUser(null); // Clear user state
        console.log('User logged out');
    };

    const handlereferalChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };
    const handleForgotemailChnage = (e) => {
        setForgotFormvalues({ ...forgotFormvalues, [e.target.name]: e.target.value });
    };
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            email: formValues?.email,
            password: formValues?.password
        }
        setLoading(true);

        try {
            const response = await axiosApi.post("/referlogin", payload);
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);            if (response.status === 200) {
                // alert("Syccessfully Login")
                toast.success( "Successfully login", {
                    position: 'top-center',
                });
            }
            navigate("/refer-dashboard/freelancer-leads")
        } catch (error) {

            if (error.response && error.response.data?.message == "Invalid credentials.") {
                console.log("ERRORREsponse",error.response)
                // setErrorLogin("Your account is not registered. Please register first.");
                setErrorLogin("Password is incorrect");
            } else if (error.response) {
                // Handle other server-side errors
                alert(`An error occurred: ${error.response.statusText || "Unknown error"}`);
            } else {
                // Handle network or client-side errors
                alert("A network error occurred. Please try again later.");
            }
        }finally {
            setLoading(false)
          }

    }


    const handleforgotPassword = async (e) => {

        e.preventDefault();
        const payload = {
            email: forgotFormvalues?.forgotEmail
        }
        try {
            const response = await axiosApi.post('/request-password-reset', payload);
            // setUser(response.data);
            console.log("User registered successfully", response.data);
            setForgotFormvalues({forgotEmail:""})
            toast.success(response?.data?.message, {
                position: 'top-center',
            });
            
        } catch (error) {
            // Debugging: log the full error object
            console.error("Error in forgot password:", error);
            console.log("Error response:", error.response);  // Log the response for debugging

            // Check if it's a 400 error
            if (error.response && error.response.status === 400) {
                alert("Your account is not registered. Please register first.");
            } else if (error.response) {
                // Handle other server-side errors
                alert(`An error occurred: ${error.response.statusText || "Unknown error"}`);
            } else {
                // Handle network or client-side errors
                alert("A network error occurred. Please try again later.");
            }
        }
    }

    return (
        <>
          {loading && LoadingPannel()}

            <GoogleOAuthProvider clientId="1010031458079-e3cn0noniskeanhmunc75n8iqpu2m0kp.apps.googleusercontent.com">
                <div className="refer-to-rearn-container">
                    <section className="refer-to-rearn-container">
                        <div className="refer-to-rearn-flex1">
                            <img src="/image/recuiter-login-banner.png" alt="Recruiter Login Banner" />
                        </div>
                        <div className="refer-to-rearn-flex2">
                            <div className="refer-to-rearn-flex2-container">
                                <div className="rerfer-earn-logo">
                                    <Link to='/'><img src="/image/nbflogo.png" alt="NBF Logo" /></Link>

                                </div>
                                <h3>Log in as referrer/influencer</h3>

                                <form className="">
                                    {/* <div className="refer-earn-input">
                                        {user ? (
                                            <button type="button" onClick={handleLogout} className="logout-button">
                                                Logout
                                            </button>
                                        ) : (
                                            <>
                                                <div className='signup-button'>
                                                    <button className='' onClick={() => login()}><GoogleIcon className='sign-up-google-icon' /> Signup with Google</button>

                                                </div>

                                            </>


                                        )}
                                    </div> */}
                                    {!showForgotEmail && (  // Normal login inputs
                                        <>
                                            <div className="refer-earn-input">
                                                <input
                                                    type="email"
                                                    placeholder="Email"
                                                    value={formValues?.email}
                                                    onChange={handlereferalChange}
                                                    name='email'
                                                    required
                                                />
                                            </div>
                                            <div className="refer-earn-input">
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    value={formValues?.password}
                                                    onChange={handlereferalChange}
                                                    name='password'
                                                    required
                                                />
                                            </div>
                                            <div style={{ color: "red" }}>{errorLogin}</div>
                                            <div className="forget-password">
                                                <p>Not a member yet ? <span><Link to='/refer-to-earn-signup'>Sign Up</Link></span></p>
                                                <p onClick={() => setShowForgotEmail(true)} style={{ cursor: 'pointer' }}>
                                                    Forgot Password ?
                                                </p>
                                            </div>
                                            <div className="refer-earn-button">
                                                <button onClick={handleLoginSubmit} type="submit">Submit</button>
                                            </div>
                                        </>
                                    )}

                                    {showForgotEmail && (  // Forgot password email input
                                        <>
                                            <div className="refer-earn-input">
                                                <input
                                                    type="email"
                                                    placeholder="Username/Email"
                                                    value={forgotFormvalues?.forgotEmail}
                                                    onChange={handleForgotemailChnage}
                                                    name='forgotEmail'

                                                    required
                                                />
                                            </div>
                                            <div className="forget-password">
                                                <p>Not a member yet? <span><Link to='/refer-to-earn-signup'>Sign Up</Link></span></p>
                                                <p onClick={() => setShowForgotEmail(false)} style={{ cursor: 'pointer' }} >
                                                    Back to Login
                                                </p>
                                            </div>
                                            <div className="refer-earn-button">
                                                <button onClick={handleforgotPassword} type="submit">Submit</button>
                                            </div>

                                        </>

                                    )}



                                </form>
                            </div>
                        </div>
                    </section>
                    <div></div>
                </div>
            </GoogleOAuthProvider>
            <ToastContainer />

        </>
    );
};

export default ReferEarn;
