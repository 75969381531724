 
import React, { useState } from 'react';
import "./recruiterleads.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosApi } from '../../_helper/api_helper';
 
import { useNavigate } from 'react-router-dom';
const RecruiterLeads = () => {
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
        organization_name: '',
        contact_email: '',
        contact_phone: '',
        city:''

    });
    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
          };
          if (!formValues?.organization_name) {
            toast.error('Name is required', toastOptions);
            return;
          }
          if (!formValues?.contact_email) {
            toast.error('Email is required', toastOptions);
            return;
          }
          if (!formValues?.contact_phone) {
            toast.error('Phone is required', toastOptions);
            return;
          }
          if (!formValues?.city) {
            toast.error('City is required', toastOptions);
            return;
          }
        const payload = {
            organization_name: formValues?.organization_name,
            contact_email: formValues?.contact_email,
            contact_phone: formValues?.contact_phone,
            city: formValues?.city,
        };

        const token = localStorage.getItem("access_token");  // Replace this with the actual token

        axiosApi.post(`/recruiter-leads`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            } 
        })
        .then(response => {
            navigate("/refer-dashboard/recruiter-leads");
            
            toast.success('Successfully posted', toastOptions);
            return;
        
            console.log("Success:", response.data);
        })
        .catch(error => {
            console.error("There was an error:", error);
        });
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Organization Name <span style={{color:"red"}}>*</span></label>
                    <div class="col-sm-9">
                        <input type="text" name='organization_name' placeholder='Enter the organization name' value={formValues?.organization_name} class="form-control" onChange={handleChange} id="inputEmail3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label">Contact Email <span style={{color:"red"}}>*</span></label>
                    <div class="col-sm-9">
                        <input type="email" name='contact_email' placeholder='Enter the contact email' value={formValues?.contact_email} class="form-control" onChange={handleChange} id="inputPassword3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label">Contact number <span style={{color:"red"}}>*</span> </label>
                    <div class="col-sm-9">
                        <input type="number" name='contact_phone' placeholder='Enter the contact number' value={formValues?.contact_phone} class="form-control" onChange={handleChange} id="inputPassword3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label">City <span style={{color:"red"}}>*</span></label>
                    <div class="col-sm-9">
                        <input type="text" name='city' placeholder='Enter the City ' value={formValues?.city} class="form-control" onChange={handleChange} id="inputPassword3" />
                    </div>
                </div>


                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
        </>
    )
}

export default RecruiterLeads
