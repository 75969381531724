import React, { useEffect, useState } from 'react'
import Header from '../../Layout/Header/Index'
import Footer from '../../Layout/Footer/Index'
import "./projectpost.scss"
import { axiosApi } from '../../_helper/api_helper'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import { Link, useNavigate } from 'react-router-dom';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel'


const ProjectPost = () => {
    const navigate = useNavigate()

    const [recuiterId, setRecuiterId] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [citiesGetData, setCitiesGetData] = useState([])
    const [subcategories, setSubcategories] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [payHour, setPayHour] = useState(false);
    const [fixedPrice, setFixedPrice] = useState(false);
    const [manySkills, setManySkill] = useState([]);
    const [skill, setSkill] = useState('');
    const [validationMsg, setValidationMsg] = useState('');
    const [loading, setLoading] = useState(false)

    const [formValues, setFormValues] = useState({
        recuiter_name: '',
        project_title: '',
        project_description: '',
        mobile: '',
        otp: '',
        skills: [],
        project_budget_hr: "",
        project_budget: "",
        skill: "",
        occupation_skill: "",
        Occupation_industry: "",
        max_perhour: '',
        min_perhour: '',
        max_fixedprice: '',
        min_fixedprice: '',
        number_of_hours: '',
        city_new: '',
    });
    const [selectedIndustryName, setSelectedIndustryName] = useState('');
    const [selectedSkillName, setSelectedSkillName] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const handleChangeskill = (e) => {
        setSkill(e.target.value);
    };

    const handleskillSoftware = (e) => {
        e.preventDefault();
        if (skill.trim()) {
            setManySkill([...manySkills, skill]);
            setSkill('');
        }
    };
    const handleSkillDelete = (index) => {
        const newSkills = manySkills.filter((_, i) => i !== index);
        setManySkill(newSkills);
    };
    const handlePerhours = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');


        setFormValues({ ...formValues, [e.target.name]: numericValue })
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [e.target.name]: e.target.value });

        // If the mobile number is changed, reset OTP sent status
        if (e.target.name === 'mobile') {
            setOtpSent(false);
            setIsOtpVerified(false); // Reset OTP verification status
        }

    };

    const handleMobileBlur = async (e) => {
        e.preventDefault()
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };
        if (formValues.mobile.length !== 10 || otpSent) {
            toast.error('Please enter a valid 10-digit mobile number.', toastOptions);
            return;;
        }

        setLoading(true);
        setPhoneNumber(formValues.mobile);
        const payload = { mobile: formValues.mobile };

        try {
            const response = await axiosApi.post("otpsend", payload);
            setOtpSent(true);
            alert("OTP has been sent to your mobile number.");
        } catch (error) {
            console.error("Error sending OTP:", error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {

        citiesGet();
    }, [])
    const citiesGet = async () => {
        const response = await axiosApi.get("/cities");
        setCitiesGetData(response?.data)
    }
    useEffect(() => {
        IndustriesGet();

    }, [])
    const IndustriesGet = async () => {
        const response = await axiosApi.get("/industries");
        setIndustries(response?.data)
    }
    const handleIndustryChange = (e) => {
        const selectedIndustryId = e.target.value;
        const selectedIndustry = industries.find(industry => industry.id === selectedIndustryId);

        setSelectedIndustryName(selectedIndustry ? selectedIndustry.name : '');
        setFormValues({ ...formValues, industry: selectedIndustryId });

        if (selectedIndustryId) {
            axiosApi.get(`/industries/${selectedIndustryId}`)
                .then(response => {
                    setSubcategories(response.data.occupation_skills || []);
                })
                .catch(error => {
                    console.error("There was an error fetching the subcategories!", error);
                    setSubcategories([]);
                });
        } else {
            setSubcategories([]);
        }
    };
    const handleCategoryChange = (e) => {
        const selectedSkillName = e.target.value;
        setSelectedSkillName(selectedSkillName);
        setFormValues({ ...formValues, occupation_skill: selectedSkillName });
    };

    const HandlePayHour = () => {
        setPayHour(true);
        setFixedPrice(false);
        // setCustomBudget(false);
        // setEstimateBudget(false);
    }
    const handleFixedPrice = (e) => {
        setFixedPrice(true);
        setPayHour(false);
        // setCustomBudget(false);
        // setEstimateBudget(false);
    }
    const handleMinBudgetChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const { name, value } = e.target;
        const parsedValue = parseFloat(numericValue);

        // Update the form values state
        setFormValues(prevState => ({
            ...prevState,
            [name]: numericValue
        }));



    };
    const handleMaxBudgetChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const { name, value } = e.target;


        // Update the form values state
        setFormValues(prevState => ({
            ...prevState,
            [name]: numericValue
        }));


    };

    const validateMinBudget = (value) => {
        const parsedValue = parseFloat(value);

        // Show error only if the user finishes entering the value and it's invalid
        if (isNaN(parsedValue) || parsedValue < 100) {
            toast.error('Please fill minimum budget with a value greater than or equal to 100.');
        }
    };

    const validateMaxBudget = (value) => {
        const parsedValue = parseFloat(value);
        const minBudget = parseFloat(formValues.min_fixedprice || 0);

        // Show error only after the user finishes typing
        if (isNaN(parsedValue) || parsedValue <= minBudget) {
            toast.error('Maximum budget should be greater than the minimum budget.');
        }
    };
    const handleOtpBlur = async () => {

        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };
        if (!formValues.otp || isOtpVerified) return;

        const payload = {
            otp: formValues.otp,
            mobile: phoneNumber,
        };

        try {
            const response = await axiosApi.post('otpverify', payload);
            setRecuiterId(response.data.client.id);  // Store recruiter ID
            localStorage.setItem("recuiter_Id", response.data.client.id);
            setIsOtpVerified(true);  // Mark OTP as verified
            setSubmitDisabled(false);
            toast.success("OTP verified successfully.", toastOptions);

            // alert("OTP verified successfully.");
        } catch (error) {

            toast.error(error?.response?.data?.error?.message, toastOptions);

        }
    };
    const handleProjectSubmit = async (e) => {

        e.preventDefault();


        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };


        if (!formValues?.recuiter_name) {
            toast.error('Recruiter Name is required', toastOptions);
            return;
        }
        if (!formValues?.project_title) {
            toast.error('Project Title is required', toastOptions);
            return;
        }
        if (!formValues.project_description) {
            toast.error('Description is required', toastOptions);
            return;
        } else if (formValues.project_description.length < 100) {
            alert("Description must be at least 100 characters long");
            return;
        }
        if (!formValues?.industry) {
            toast.error('Category is required', toastOptions);
            return;
        }
        if (!formValues?.occupation_skill) {
            toast.error('Sub Category  is required', toastOptions);
            return;
        }
        if (!manySkills) {
            toast.error('Skill is required', toastOptions);
            return;
        }

        if (!payHour && !fixedPrice) {
            toast.error('Please select the option. How do you want to pay?', toastOptions);
            return;
        }
        if (payHour) {
            if (!formValues.min_perhour || isNaN(parseFloat(formValues.min_perhour))) {
                toast.error('Minimum per hour price is required.', toastOptions);
                return;
            }
            if (!formValues.max_perhour || isNaN(parseFloat(formValues.max_perhour))) {
                toast.error('Maximum per hour price is required.', toastOptions);
                return;
            }
            if (parseFloat(formValues.min_perhour) < 100) {
                toast.error('Minimum budget per hour should be at least 100.', toastOptions);
                return;
            }
            if (parseFloat(formValues.max_perhour) <= parseFloat(formValues.min_perhour)) {
                toast.error('Maximum per hour price should be greater than the minimum price.', toastOptions);
                return;
            }
            if (!formValues.number_of_hours) {
                toast.error('Please fill the number of hours.', toastOptions);
                return;
            }
        }
        if (fixedPrice) {
            if (!formValues.min_fixedprice || isNaN(parseFloat(formValues.min_fixedprice))) {
                toast.error('Minimum fixed price is required.', toastOptions);
                return;
            }
            if (!formValues.max_fixedprice || isNaN(parseFloat(formValues.max_fixedprice))) {
                toast.error('Maximum fixed price is required.', toastOptions);
                return;
            }
            if (!formValues.min_fixedprice || formValues.min_fixedprice < 100) {
                toast.error('Minimum fixed price should be at least 100.', toastOptions);
                return;
            }
            if (parseFloat(formValues.max_fixedprice) <= parseFloat(formValues.min_fixedprice)) {
                toast.error('Maximum fixed price should be greater than the minimum price.', toastOptions);
                return;
            }
        }

        if (!formValues?.city_new || !formValues.city_new === '') {
            toast.error('Location for project is required', toastOptions);
            return;
        }
        if (!formValues?.mobile || !formValues.mobile === '') {
            toast.error('Verify your mobile number', toastOptions);
            return;
        }

        if (!formValues?.otp) {
            alert("Please fill the OTP");
            return;
        }
        const payload = {
            // recuiter_name: formValues.recuiter_name,
            project_title: formValues.project_title,
            project_description: formValues.project_description,
            skills: manySkills.map(skill => ({ name: skill })),
            industry: selectedIndustryName,
            occupation_skill: selectedSkillName,
            city_new: formValues?.city_new,
        };
        if (payHour) {
            payload.min_perhour = Number(formValues.min_perhour);
            payload.max_perhour = Number(formValues.max_perhour);
            payload.number_of_hours = Number(formValues.number_of_hours);
        } else if (fixedPrice) {
            payload.min_fixedprice = Number(formValues.min_fixedprice);
            payload.max_fixedprice = Number(formValues.max_fixedprice);
        }

        if (!recuiterId && !isOtpVerified) {
            // If OTP is not verified, verify it before proceeding
            const payloadOtp = {
                otp: formValues.otp,
                mobile: phoneNumber,
            };

            try {
                const otpResponse = await axiosApi.post('otpverify', payloadOtp);
                setRecuiterId(otpResponse.data.client.id);  // Store recruiter ID
                localStorage.setItem("recuiter_Id", otpResponse.data.client.id);
                const recuiterIds = otpResponse.data.client.id;
                setIsOtpVerified(true);  // Mark OTP as verified
                setSubmitDisabled(false);
                toast.success("OTP verified successfully.", toastOptions);
                if (recuiterIds) {
                    try {
                        const response = await axiosApi.post(`/multijob/${recuiterIds}`, payload);
                        const result = await axiosApi.put(`/recuiter/${recuiterIds}`, { recuiter_name: formValues.recuiter_name });
                        alert("Project submit successfully")
                        navigate("/recuiter-profile-completed");

                    } catch (error) {
                        console.error("Error submitting project:", error);
                    }
                }
            } catch (error) {
                toast.error(error?.response?.data?.error?.message, toastOptions);
                return; // Stop submission if OTP verification fails
            }
        }





    };


    return (
        <>
            {loading && <LoadingPannel />}
            {/* <Header /> */}
            <nav style={{ border: "1px solid lightgrey" }} className="navbar navbar-light bg-light">
                <div className="container">
                    <Link className="navbar-brand" to="/project-post">
                        <img src="/image/nbflogo.png" alt="" width="60" height="40" />
                    </Link>
                </div>
            </nav>
            <div className="project-post">
                <div className="recuiter-proofessional-inner">
                    <form className="row g-3">
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Recruiter Name <span style={{ color: "red" }}>*</span></label>
                            <input type="text" name='recuiter_name' placeholder='Enter the Recruiter Name' onChange={handleChange} value={formValues?.recuiter_name} className="form-control" id="recuiter_name" />
                        </div>
                        <div className="col-md-6">
                            <label for="inputPassword4" className="form-label">Project Title <span style={{ color: "red" }}>*</span></label>
                            <input type="text" name='project_title' placeholder='Enter the Project Title  ' onChange={handleChange} value={formValues?.project_title} className="form-control" id="project_title" />
                        </div>
                        <div className="col-12">
                            <label for="inputAddress" className="form-label">Project Description <span style={{ color: "red" }}>*</span></label>
                            <textarea type="text" className="form-control" name='project_description' onChange={handleChange} value={formValues?.project_description} rows="4" id="project_description" placeholder="Project Description (Minimum 100 characters)" />
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Category <span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleIndustryChange}
                                //  onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='industry' value={formValues?.industry} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option selected>Select Category</option>
                                {industries?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.id}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Subcategory <span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleCategoryChange}
                                name="occupation_skill"
                                value={formValues.occupation_skill}
                                disabled={!subcategories.length && !selectedIndustry}
                                style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option selected>Select Sub Category</option>
                                {selectedIndustry && subcategories.length === 0 && (
                                    <option value="" disabled>No records found</option>
                                )}
                                {subcategories?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.name}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="skill-expertise">
                            <div> <label style={{ fontWeight: "500", fontSize: "16px" }} htmlFor="">Enter the skill set
                                you want to use for this project. <span style={{ color: "red" }}>*</span> </label>
                                <Tooltip placement="top" title={
                                    <>
                                        <div><b>Note:</b></div>
                                        <div>Enter your Skillset for this project</div>
                                        <div> e.g. HTML, CSS, Python,etc..</div>

                                    </>
                                } arrow>
                                    <InfoIcon style={{ color: "grey" }} />
                                </Tooltip>
                            </div>
                            <div className='skill-expertise-inner'>
                                <div className='skill-expertise-input'>
                                    <input
                                        type="text"
                                        value={skill}
                                        fullWidth
                                        onChange={handleChangeskill}
                                        placeholder="Enter your skills e.g. HTML, CSS, Python, etc.."
                                    />
                                    {/* <input onChange={handleSoftwareChange} value={formvaluesSoftware?.name} fullWidth type="text" name="name" id="" /> */}
                                </div>
                                <div className='skill-expertise-button'><button onClick={handleskillSoftware} className='btn btn-danger'>+Add Skill</button></div>
                            </div>
                            <div className='skill-expertise-data'>

                                {manySkills && manySkills.length > 0 && manySkills.map((item, index) => (
                                    <>
                                        <div className='software-known-button-conatiner'>
                                            <button key={index} style={{ backgroundColor: "#F5E8DD" }} className='btn btn ms-2 software-known-button'>{item}</button>
                                            <CancelIcon
                                                className='software-konw-icon'

                                                onClick={() => handleSkillDelete(index)}
                                            />
                                        </div>

                                    </>
                                ))}

                            </div>
                        </div>
                        <div className="pay-layout">
                            <div> <label style={{ fontWeight: "700", fontSize: "16px" }} htmlFor="">What is your preferred payment method? <span style={{ color: "red" }}>*</span> </label>

                            </div>
                            <div className="pay-layout-inner">
                                <div onClick={HandlePayHour}
                                    className={`pay-hour ${payHour ? 'selected' : ''}`}
                                >
                                    <h6>Pay by the hour</h6>

                                    <p>Hire based on an hourly rate and pay for hours billed. Best for ongoing work</p>
                                </div>
                                <div onClick={handleFixedPrice}
                                    className={`pay-hour ${fixedPrice ? 'selected' : ''}`}
                                >
                                    <h6>Pay fixed price</h6>
                                    <p>Agree on a price and release payment when  the job is done. Best for  one-off task</p>
                                </div>

                            </div>

                            <div className='toggle-budget' >
                                {payHour &&

                                    <>

                                        <div className='select-main-heading'>Price Range Per Hour <Tooltip placement="top" title={
                                            <>
                                                <div><b>Note:</b></div>
                                                <div>Typically freelancer with following skillset charge the following rates</div>
                                                <div> Beginner : 100 - 500 per/hour</div>
                                                <div> Intermediate : 500+ - 1000 per/hour</div>
                                                <div> Experienced : 1000+ per/hour</div>

                                            </>
                                        } arrow>
                                            <InfoIcon style={{ color: "grey" }} />
                                        </Tooltip></div>
                                        <div className='per-hour-budget'>
                                            <div className='custom-budget'>
                                                <p>Minimum Price</p>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><CurrencyRupeeIcon /></span>
                                                    <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                        placeholder='Enter minimum price'
                                                        value={formValues?.min_perhour || ''}

                                                        name="min_perhour"
                                                        onChange={handleMinBudgetChange} id="minBudget"
                                                        onBlur={(e) => validateMinBudget(e.target.value)}
                                                    />
                                                    <span className="input-group-text">Per/Hour</span>
                                                </div>

                                            </div>
                                            <div className='custom-budget'>
                                                <p>Maximum Price</p>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><CurrencyRupeeIcon /></span>
                                                    <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                        placeholder='Enter maximum price'
                                                        value={formValues?.max_perhour || ''}
                                                        name="max_perhour"
                                                        onChange={handleMaxBudgetChange}
                                                        onBlur={(e) => validateMaxBudget(e.target.value)}
                                                        id="maxBudget"
                                                    />
                                                    <span className="input-group-text">Per/Hour</span>
                                                </div>

                                            </div>
                                            <div className='custom-budget'>
                                                <p>Number of hours required for project</p>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><AccessTimeFilledIcon /></span>
                                                    <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                        placeholder='Enter number of hours'
                                                        value={formValues?.number_of_hours || ''}
                                                        name="number_of_hours"
                                                        onChange={handlePerhours}
                                                    // onBlur={(e) => validateMaxBudget(e.target.value)}
                                                    // id="maxBudget"
                                                    />
                                                    <span className="input-group-text">Per/Hour</span>
                                                </div>

                                            </div>
                                        </div>


                                        {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}
                                    </>


                                }
                                {fixedPrice &&
                                    <>
                                        <div className='select-main-heading'>Fixed Price Range <Tooltip placement="top" title={
                                            <>
                                                <div><b>Note:</b></div>
                                                <br />
                                                <div> Please consider the number of hours required to be invested in the project. Further, please also consider skill set requirement while determining the fixed price of the project. Typically freelancers with the following skill set charge the following rates:</div>
                                                <br />
                                                <div> Beginner : 100 - 500 per/hour</div>
                                                <div> Intermediate : 500+ - 1000 per/hour</div>
                                                <div> Experienced : 1000+ per/hour</div>
                                                <br />
                                                <div> Also please read the <Link style={{ color: "white" }} target='_blank' to={`/term-and-condition/fixed-price-escrow-instruction`}>Term and Condtion </Link>
                                                    carefully.
                                                </div>


                                            </>
                                        } arrow>
                                            <InfoIcon style={{ color: "grey" }} />
                                        </Tooltip></div>
                                        <div className='per-hour-budget'>
                                            <div className='custom-budget'>
                                                <p>Minimum Price</p>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><CurrencyRupeeIcon /></span>
                                                    <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                        placeholder='Enter minimum price'
                                                        value={formValues?.min_fixedprice || ''}


                                                        onBlur={(e) => validateMinBudget(e.target.value)}
                                                        onChange={handleMinBudgetChange} name="min_fixedprice"
                                                        id="minBudget"
                                                    />
                                                    {/* <span className="input-group-text">Per/Hour</span> */}
                                                </div>
                                                {/* <input type="text" placeholder='Enter minimum budget' onBlur={handleMinBudgetChange} name="min_perhour" id="minBudget" /> */}
                                            </div>
                                            <div className='custom-budget'>
                                                <p>Maximum Price</p>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><CurrencyRupeeIcon /></span>
                                                    <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                        value={formValues?.max_fixedprice || ''}

                                                        placeholder='Enter maximum price' onChange={handleMaxBudgetChange}
                                                        onBlur={(e) => validateMaxBudget(e.target.value)}
                                                        name="max_fixedprice" id="maxBudget"
                                                    />
                                                    {/* <span className="input-group-text">Per/Hour</span> */}
                                                </div>
                                                {/* <input type="text" placeholder='Enter maximum budget' onBlur={handleMaxBudgetChange} name="max_perhour" id="maxBudget" /> */}
                                            </div>
                                        </div>
                                        {validationMsg && <div style={{ color: 'red' }}>{validationMsg}</div>}


                                    </>

                                }



                            </div>




                        </div>
                        <div className="col-md-6 col-12">
                            <label for="inputPassword4" className="form-label">Select the location you want to serve for your Project<span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleChange}
                                //  onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='city_new' value={formValues?.city_new} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option value={''} selected>Select Location</option>
                                {citiesGetData?.map((item) => (
                                    <>
                                        <option value={item?.city_name} style={{ textTransform: "capitalize" }}  >{item?.city_name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="col-md-6 mobile-verify">
                            <div className="col-md-7 col-12">
                                <label for="Mobilenumber" className="form-label">Mobile Number <span style={{ color: "red" }}>*</span></label>
                                <input type="text"
                                    pattern="[0-9]{10}" onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only digits
                                    }}
                                    name="mobile"
                                    placeholder='Verify your Mobile Number'
                                    onChange={handleChange} minLength={10} maxLength={10} value={formValues.mobile} className="form-control" id="Mobilenumber" />
                            </div>
                            {/* <div className="col-md-1"></div> */}
                            <div className="col-md-4 col-12 mt-1 mt-md-4">
                                <label for="inputPassword4" className="form-label">   </label>

                                <button style={{ backgroundColor: "#FF3131" }} onClick={handleMobileBlur} className='btn btn-primary '>Click to Verify</button>
                            </div>
                        </div>



                        {otpSent && (
                            <div className="col-md-4 col-12">
                                <label for="inputPassword4" style={{color:"red"}} className="form-label">OTP <span>*</span></label>
                                <input type="number" className="form-control" name="otp"
                                    onChange={handleChange}
                                    // onBlur={handleOtpBlur}
                                    placeholder='Enter the OTP'
                                    value={formValues.otp} id="otp" />
                            </div>
                        )}


                        {/* {submitDisabled && ( */}
                        <div className="col-12">
                            <button style={{ background: '#FF3131' }} onClick={handleProjectSubmit} type="submit" className="btn btn-primary">Submit Project</button>
                        </div>


                    </form>
                </div>

            </div>
            <ToastContainer />
            {/* <Footer /> */}
        </>

    )
}

export default ProjectPost
