import React, { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./verifyemail.scss";
import { Link, useLocation, useParams } from 'react-router-dom';
import { axiosApi } from '../../_helper/api_helper';

const VerifyEmail = () => {
    const { token } = useParams();
    console.log("token", token)

    useEffect(() => {
        if (token) {
            verifyemailApi();
        }

    }, [token])

    const verifyemailApi = async () => {
        try {
            const response = await axiosApi.get(`/verify-email/${token}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                } 
            });
            console.log("   ", response);
        } catch (error) {
            console.log("verify email error", error);
        }
    }

    return (
        <>
            <div className="verify-email">
                <div className="verify-email-container">
                    <p className='verify-email-icon'><CheckCircleIcon className='icon-check' /></p>
                    <p> Your Email has been successfully verified.</p>
                    <button> <Link to="/refer-login">Go to Login</Link></button>
                </div>
            </div>
        </>
    )
}

export default VerifyEmail
